import LayoutPrivate from '@/layouts/private/layout-private.vue';
import mResults from '@/modules/m-results/m-results.vue';
import resultNew from './new/new.vue';
// import userEdit from './edit/edit.vue';
// import {eventBus} from '@/main'

export default {
  name: 'users',

  components: {
    'layout-private': LayoutPrivate,
    'm-results': mResults,
    'result-new': resultNew
  },

  data() {
    return {
      resultState: null
    };
  },

  computed: {
    uuid: function() {
      return this.$route.params.uuid;
    },

    currentResultComponent: function() {
      let comp = 'default';

      if(this.resultState == 'new') {
        comp = 'result-' + this.resultState;
      }
      return comp;
    },

    resultSelected: function() {
      return this.$store.state.resultSelected;
    }
  },

  watch: {
    uuid: function() {
      this.getParam();
    }
  },

  methods: {
    getParam() {
      if(this.$route.params) {
        switch(this.$route.params.uuid) {
          case undefined:
            this.resultState = null;
          break;
          case '':
            this.resultState = null;
          break;
          case 'new':
            this.resultState = 'new';
          break;
          default:
            this.resultState = 'edit';
          break;
        }
      }else{
        this.resultState = null;
      }
    }
  },

  mounted() {
    this.getParam();
  }
};

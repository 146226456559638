// import EventBus from '@/config/EventBus';
import ApiHelper from '@/helpers/ApiHelper';
import ErrorHelper from "@/helpers/ErrorHelper";
import StringHelper from "@/helpers/StringHelper";
// import Notification from "@/utils/Notification";
import CONST from "@/utils/Constants"; // eslint-disable-line no-unused-
import oButton from "@/objects/o-button/o-button.vue";
import oToast from "@/objects/o-toast/o-toast.vue";
import oInput from "@/objects/o-input/o-input.vue";
import oSelect from "@/objects/o-select/o-select.vue";
import oCheckbox from "@/objects/o-checkbox/o-checkbox.vue";
import oLabel from "@/objects/o-label/o-label.vue";
import oValidationNotification
  from '@/objects/o-validation-notification/o-validation-notification.vue';
// import ObjectHelper from "../../../helpers/ObjectHelper";

export default {
  name: 'mUserNew',

  components: {
    "o-button": oButton,
    "o-input": oInput,
    "o-select": oSelect,
    "o-label": oLabel,
    "o-toast": oToast,
    "o-checkbox": oCheckbox,
    "o-validation-notification": oValidationNotification
  },

  data() {
    return {
      send_notifications: false,
      barcode: "",
      report: null,
      isLoading: false,
      isChecking: false,
      dna: {
        dnaReceived: {
          status: false,
          date: null,
          notify: false
        },
        dnaSentToLab: {
          status: false,
          date: null,
          notify: false
        },
        dnaInBucket: {
          status: false,
          date: null,
          notify: false
        },
        analysisDone: {
          status: false,
          date: null,
          notify: false
        }
      },
      result: {
        A: 0,
        C: 0,
        T: 0,
        G: 0,
        H: 0,
        O: 0
      }
    };
  },
  watch: {
    send_notifications: function () {
      if (this.send_notifications === true) {
        alert('Are you sure? You can also regenerate a report without sending notifications.');
      }
    }
  },

  methods: {

    clear() {

      this.report = null;
      this.send_notifications = null;
      this.dnaSentToLabDate = null;
      // this.barcode = "";
      this.dnaReceived = null;
      this.dnaSentToLab = null;
      this.dnaInBucket = null;
      this.dnaAnalysisDone = null;
      this.result = {
        A: 0,
        C: 0,
        T: 0,
        G: 0,
        H: 0,
        O: 0
      };
    },

    loadResults()
    {
      this.isLoading = true;
      if(!this.isChecking) {
        this.isChecking = true;
        ApiHelper.get(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.BARCODES.CHECK, [{barcode: this.barcode.toUpperCase()}]), {})
          .then((response) => {
            this.report = response.data;

            if (response.data.result)
              this.result = response.data.result;

            this.updateDates(response.data);
          })
          .catch(() => {
            alert("Invalid barcode provided")
          })
          .finally(() => {
            this.isLoading = false;
            this.isChecking = false;
          })
      }
    },

    saveResults() {

      if (this.isValid) {
        this.isLoading = true;
        ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.RESULTS.NEW, [{id: this.barcode.toUpperCase()}]), {
          dna: this.dna,
          A: parseFloat(this.result.A),
          C: parseFloat(this.result.C),
          T: parseFloat(this.result.T),
          G: parseFloat(this.result.G),
          H: parseFloat(this.result.H),
          O: parseFloat(this.result.O)
        })
          .then(() => {
            alert("Advice generated")

            // this.clear();
          })
          .catch(error => {
            alert("Something went wrong, please check your input.")
            ErrorHelper.catch(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    formatDate(stringDate) {
      if(stringDate) {
        let date = new Date(stringDate);
        return [
          date.getFullYear(),
          ('0' + (date.getMonth() + 1)).slice(-2),
          ('0' + date.getDate()).slice(-2)
        ].join('-');
      }
      else return null;
    },

    updateDates(requestResponse){
      this.dna.dnaInBucket.date = this.formatDate(requestResponse.dnaInBucket);
      this.dna.analysisDone.date = this.formatDate(requestResponse.analysisDone);
      this.dna.dnaReceived.date = this.formatDate(requestResponse.dnaReceived);
      this.dna.dnaSentToLab.date = this.formatDate(requestResponse.dnaSentToLab);
    }
  },

  computed: {
    dnaIsValid() {
      return StringHelper.isNotEmpty(this.result.A.toString())
        && StringHelper.isNotEmpty(this.result.C.toString())
        && StringHelper.isNotEmpty(this.result.G.toString())
        && StringHelper.isNotEmpty(this.result.H.toString())
        && StringHelper.isNotEmpty(this.result.T.toString())
        && StringHelper.isNotEmpty(this.result.O.toString());
    },


    percentageResults() {
      return (isNaN(parseFloat(this.result.A)) ? 0 : parseFloat(this.result.A)) +
        (isNaN(parseFloat(this.result.C)) ? 0 : parseFloat(this.result.C)) +
        (isNaN(parseFloat(this.result.G)) ? 0 : parseFloat(this.result.G)) +
        (isNaN(parseFloat(this.result.H)) ? 0 : parseFloat(this.result.H)) +
        (isNaN(parseFloat(this.result.T)) ? 0 : parseFloat(this.result.T)) +
        (isNaN(parseFloat(this.result.O)) ? 0 : parseFloat(this.result.O));
    },

    statesAreValid() {

      if (!StringHelper.isNotEmpty(this.dna.analysisDone.date))
        return false;

      if (!StringHelper.isNotEmpty(this.dna.dnaInBucket.date))
        return false;

      if (!StringHelper.isNotEmpty(this.dna.dnaSentToLab.date))
        return false;

      return !(!StringHelper.isNotEmpty(this.dna.dnaReceived.date));
    },
    barcodeIsValid() {
      return StringHelper.isNotEmpty(this.barcode)
        && this.barcode.length === 10
        && this.barcode.toUpperCase().startsWith("CP", 0);
    },
    isValid() {

      return this.barcodeIsValid
        && !this.isLoading
        && this.statesAreValid
        && this.dnaIsValid;
    }
  }
};

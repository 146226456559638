import EventBus from '@/config/EventBus';
import ApiHelper from '@/helpers/ApiHelper';
import ErrorHelper from "@/helpers/ErrorHelper";
import CONST from "@/utils/Constants"; // eslint-disable-line no-unused-vars
import oUser from "@/objects/o-user/o-user.vue";
import oButton from "@/objects/o-button/o-button.vue";

export default {
    name: 'mUsers',

    components: {
      "o-user": oUser,
      "o-button": oButton
    },

    data() {
      return {
        style: '0px',
        users: null
      }
    },

    methods: {
      getUsers() {
        ApiHelper.get(CONST.API.ROUTE.USERS.OVERVIEW, {headerType: 'basic'})
                .then(response => {
                    this.users = response.data;

                    for (const [i, user] of Object.entries(this.users)) {
                      if(user.email == CONST.USER.LOCKED.EMAIL) {
                        this.users[i].locked = true;
                      }else{
                        this.users[i].locked = false;
                      }
                    }
                })
                .catch(error => {
                    ErrorHelper.catch(error);
                })
                .finally(() => {
                    this.loading = false;
                });
      },

      isActive(id) {
        let active = false;

        if(this.$route.params.uuid) {
          if(id == this.$route.params.uuid) {
            active = true;
          }
        }

        return active;
      },

      addUser() {
        this.$router.push(CONST.ROUTE.USERS.NEW);
      },

      setStyle() {
        let height = 0;

        if(document.getElementById('m-users__top')) {
          height = document.body.clientHeight - (document.getElementById('m-users__top').clientHeight + 60);
        }

        this.style = "height: " + height + "px;";
      }
    },

    mounted(){
      this.getUsers();
      this.setStyle();

      EventBus.$on(CONST.EVENTS.USER.ADDED, this.getUsers);
      EventBus.$on(CONST.EVENTS.USER.UPDATED, this.getUsers);
      EventBus.$on(CONST.EVENTS.USER.DELETED, this.getUsers);

      window.addEventListener('resize', this.setStyle);
    }
};
